<script setup lang="ts">
import dayjs, { Dayjs } from "dayjs";

import type { Promotion } from "@/types";

const { t } = useT();

const props = defineProps<{
	image: string;
	title: string;
	subTitle: string;
	coins: number;
	entries: number;
	dateStart?: string | number | Dayjs;
	type?: Promotion["type"];
	dateFinish?: string | number | Dayjs;
	isActive?: boolean;
	isSubscribed?: boolean;
}>();

const textBtn = computed(() => {
	if (!props.isActive) {
		return t("More Info");
	}
	return props.isSubscribed ? t("Play now") : t("Join Now");
});
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 135,
			height: 246,
			src: image,
			format: 'avif',
			loading: 'lazy',
			alt: 'race-promotion-img'
		}"
		width="328px"
		height="246px"
		class="card-race"
	>
		<div class="free">
			<ASvg name="48/free" class="icon" />
			<AText
				variant="tempe"
				class="text-tlalnepantla free-text text-carabanchel"
				:modifiers="['semibold', 'uppercase']"
			>
				{{ t("Free") }}
			</AText>
		</div>
		<AText :modifiers="['semibold', 'uppercase']">
			{{ title }}
		</AText>
		<AText variant="topeka" class="subtitle" :modifiers="['semibold']">
			{{ subTitle }}
		</AText>
		<MRaceTimer
			variant="topeka"
			hideIcon
			contentColor="var(--coro)"
			:text="isActive ? t('Finish in:') : t('Starts at')"
			:date="isActive ? dateFinish : dayjs(dateStart).format('h:mm A')"
		/>
		<MPrizeFund v-if="coins" variant="coins" img="/nuxt-img/prizes/funcoins@2x.png" :icon-size="24">
			<AText
				variant="tanzay"
				class="text-tlalnepantla text-count"
				data-tid="promo-race-coins"
				:modifiers="['semibold', 'uppercase']"
			>
				{{ numberFormat(coins) }}
			</AText>
		</MPrizeFund>
		<MPrizeFund v-if="entries" variant="entries" img="/nuxt-img/prizes/citycoins@2x.png" :icon-size="24">
			<AText
				variant="tanzay"
				class="text-tlalnepantla text-count"
				data-tid="promo-race-entries"
				:modifiers="['semibold', 'uppercase']"
			>
				{{ numberFormat(entries) }}
			</AText>
		</MPrizeFund>
		<AButton variant="primary" class="button" size="s">
			<AText :modifiers="['semibold']" class="text-tlalnepantla">
				{{ textBtn }}
			</AText>
		</AButton>
	</ACard>
</template>

<style lang="scss" scoped>
.card-race {
	padding: gutter(2);
	justify-content: center;
	gap: gutter(1);
	background: var(--grimsby);

	&:deep(.prize) {
		gap: gutter(0.5);
	}

	.promo-timer {
		display: flex;
		align-items: center;
		gap: gutter(0.5);
	}

	.text-count {
		text-shadow: 0 4px 4px var(--chuncheon);
	}

	.subtitle {
		max-width: 180px;
	}

	.button {
		min-width: 140px;
		width: max-content;
	}

	.free {
		position: absolute;
		right: 12px;
		top: 0;
		width: 60px;
		height: 49px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 gutter(0.4) gutter(2) 0;

		&:deep(svg) {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		&-text {
			position: relative;
			z-index: 2;
		}
	}
}
</style>
